import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from './context';
import { useNavigate } from 'react-router-dom';

function TopBar({ toggleSideBar }) {
  const { setSummonerName, server, setServer, region, setRegion } = useContext(AppContext);

  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleRegionClick = (selectedRegion) => {
    setServer(selectedRegion);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    // Perform region check after `server` state has been updated
    if (server === "na1" || server === "br1" || server === "la1" || server === "la2") {
      setRegion("americas");
    } else if (server === "jp1" || server === "kr") {
      setRegion("asia");
    } else if (server === "eun1" || server === "euw1" || server === "tr1" || server === "ru") {
      setRegion("europe");
    } else if (server === "oc1" || server === "ph2" || server === "sg2" || server === "th2" || server === "tw2" || server === "vn2") {
      setRegion("sea");
    }
  }, [server, setRegion]);

  useEffect(() => {
    // Log the updated value of `region` whenever it changes
    console.log(region);
  }, [region]);


  const getSumName = () => {
    const inputElement = document.getElementById('summoner-input');
    const name = inputElement.value;
    setSummonerName(name);
    navigate('/summoner');
  };

  return (
    <div className="fixed w-full top-0 h-[78px] bg-gray-900 z-50 flex items-center justify-center px-4">
      <div
        className="fixed left-5 hover:bg-blue-600 duration-300 rounded-md py-1 px-2"
        onClick={toggleSideBar}
      >
        <div className="flex row align-middle hover:cursor-pointer group">
          <i className="bi bi-app-indicator px-2 py-1 bg-blue-600 rounded-md"></i>
          <h1 className="font-bold group-hover:text-black text-gray-200 duration-500 text-[15px] ml-3 py-1 max-xl:hidden">
            League Of Statistics
          </h1>
        </div>
      </div>
      <div className="flex items-center bg-white rounded-md pl-4">
        <i className="bi bi-search text-gray-500"></i>
        <input
          id="summoner-input"
          type="text"
          placeholder="Summoner Name"
          className="ml-2 outline-none bg-transparent xl:w-96 max-sm:w-24 max-md:w-36 max-lg:w-64"
        />
        <button
          className="text-black hover:bg-blue-600 hover:text-gray-100 hover:px-3 duration-300 bg-gray-200 px-2 h-10 rounded-md"
          onClick={getSumName}
        >
          <i className="bi bi-binoculars text-gray-500 px-1.5"></i>
          Search
        </button>
      </div>
      <div className="relative ml-4">
        <button
          className="text-white hover:bg-blue-600 duration-300 px-2 py-1 h-10 rounded-md hover:px-3"
          onClick={toggleDropdown}
        >
          <i className="bi bi-three-dots-vertical"></i>
          Region
        </button>
        <div
          className={`absolute mt-2 w-20 bg-white rounded-md shadow-lg ${isDropdownOpen
              ? 'opacity-100 translate-y-0 duration-500 transition-opacity transform'
              : 'opacity-0 -translate-y-150 duration-500 transition-opacity transform'
            } ${!isDropdownOpen ? 'dropdown-list-disabled' : ''}`}
        >
          <ul className="py-2 flex flex-col items-center">
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('tr1')}
            >
              TR
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('euw1')}
            >
              EUW
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('eun1')}
            >
              EUN
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('jp1')}
            >
              JP
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('kr')}
            >
              KR
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('la1')}
            >
              LA1
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('la2')}
            >
              LA2
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('na1')}
            >
              NA1
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('oc1')}
            >
              OC1
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('ru1')}
            >
              RU
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('ph2')}
            >
              PH2
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('sg2')}
            >
              SG2
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('th2')}
            >
              TH2
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('tw2')}
            >
              TW2
            </li>
            <li
              className="px-4 py-2 hover:bg-blue-600 hover:cursor-pointer duration-300 rounded-md"
              onClick={() => handleRegionClick('vn2')}
            >
              VN2
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TopBar;

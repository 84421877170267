import React, { useEffect, useContext } from 'react';
import './ChampionsPage.css';
import { AppContext } from './context';
import ReactHtmlParser from 'html-react-parser';

function ItemToolTip({ ItemId, itemRgb, items }) {
  const rgb = itemRgb

  if (rgb.r === 41 && rgb.b === 44 && rgb.b === 52) {
    return null
  }

  const description = items.data[ItemId]?.description;
  const replacedDescription = description
    .replaceAll("<attention>", "<div className='attention'>")
    .replaceAll("</attention>", "</div>")
    .replaceAll("<mainText>", "<div className='main-text'>")
    .replaceAll("</mainText>", "</div>")
    .replaceAll("<stats>", "<div className='stats'>")
    .replaceAll("</stats>", "</div>")
    .replaceAll("<passive>", "<div className='passive'>")
    .replaceAll("</passive>", "</div>")
    .replaceAll("<rarityMythic>", "<div className='rarity-mythic'>")
    .replaceAll("</rarityMythic>", "</div>")
    .replaceAll("<rarityLegendary>", "<div className='rarity-legendary'>")
    .replaceAll("</rarityLegendary>", "</div>")
    .replaceAll("<br>", "<br/>").replaceAll("<li>", "<li className='inline'/>")

  const parsedDescription = ReactHtmlParser(replacedDescription);


  return (

    <div
      className='w-96 mb-5 rounded-xl z-50 max-sm:mx-3 max-sm:w-64 backgroundstyle'
      style={{
        backgroundColor: `rgb(${rgb.r + (255 - rgb.r) * 0.3}, ${rgb.g + (255 - rgb.g) * 0.3}, ${rgb.b + (255 - rgb.b) * 0.5})`,
        filter: 'contrast(100%)',
        background: `radial-gradient(circle, rgb(${rgb.r + (255 - rgb.r) * 1}, ${rgb.g + (255 - rgb.g) * 1}, ${rgb.b + (255 - rgb.b) * 1}) 0%, 
        rgb(${rgb.r + (255 - rgb.r) * 0.3}, ${rgb.g + (255 - rgb.g) * 0.3}, ${rgb.b + (255 - rgb.b) * 0.3}) 100%`
      }}
    >
      <div className="champHeaders flex items-center p-3 mx-1 itemContainer itemtitle justify-center">
        <img className='h-12 w-12 rounded-lg' src={`http://ddragon.leagueoflegends.com/cdn/13.13.1/img/item/${ItemId}.png`} />
        <h1 className='ml-3'
          style={{
            backgroundColor: `rgba(${rgb.r + (255 - rgb.r) * 0.6}, ${rgb.g + (255 - rgb.g) * 0.6}, ${rgb.b + (255 - rgb.b) * 0.6}, 0.4)`,
            borderRadius: '10px',
            color: 'white',
            display: 'inline-block',
            paddingInline: '10px',
            backdropFilter: "blur(10px)",
          }}
        >
          {items.data[ItemId]?.name}
        </h1>
      </div>


      <div
        className='py-2 px-3 mx-3 mb-3 text-justify '
        style={{
          backgroundColor: `rgba(${rgb.r + (255 - rgb.r) * 0.6}, ${rgb.g + (255 - rgb.g) * 0.6}, ${rgb.b + (255 - rgb.b) * 0.6}, 0.4)`,
          borderRadius: '10px',
          backdropFilter: "blur(10px)",

        }}
      >
        <p className='text-sm' style={{ color: 'white' }}>{parsedDescription}</p>
      </div>
      <div className='h-1'></div>
    </div>
  );
}

export default ItemToolTip;

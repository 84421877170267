import { useState, useEffect, useContext } from "react";
import { AppContext } from './context';
import style from "./ChampionsPage.css";
import ItemToolTip from "./ItemToolTip";
import getAverageRGB from "./AvarageRGBFunc";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


function getRGB(getAverageRGB, URL) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL;
    img.crossOrigin = "Anonymous";

    img.addEventListener('load', () => {
      const averageRGB = getAverageRGB(img);
      resolve(averageRGB);
    });
  });
}

function formatAsPercentage(num) {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(num);
}

function infoText(icon, description, value) {
  return (
    <div className="info p-3 mx-1 flex items-center">
      
        {(icon === null ? "" : <div className="iconWrap"><img src={icon} className="info-icon"/></div>)}
      
      <h1 className="ml-3 info-header">
        {description}: {value}
      </h1>
    </div>
  );
}


function SearchSummoner() {
  const { summonerName, server, region, latestPatch, items, setItems, rgb, setRGB } = useContext(AppContext);
  const [last20Matches, setLast20Matches] = useState([]);
  const [maps, setMaps] = useState({});
  const [backgroundImage, setBackgroundImage] = useState(null);



  const riotKey = "RGAPI-6a81fa11-15b7-4da1-b84b-f427ce4eaa9a";
  const sumName = summonerName;

  const link = `https://${server}.api.riotgames.com/lol/summoner/v4/summoners/by-name/${sumName}?api_key=${riotKey}`;

  const [SummonerInfo, setSummonerInfo] = useState({
    id: '',
    accountId: '',
    puuid: '',
    name: '',
    profileIconId: '',
    revisionDate: '',
    summonerLevel: '',
  });






  useEffect(() => {
    const fetchSummonerInfo = async () => {
      try {
        const response = await fetch(link);
        const summonerInfo = await response.json();
        setSummonerInfo({
          id: summonerInfo.id,
          accountId: summonerInfo.accountId,
          puuid: summonerInfo.puuid,
          name: summonerInfo.name,
          profileIconId: summonerInfo.profileIconId,
          revisionDate: summonerInfo.revisionDate,
          summonerLevel: summonerInfo.summonerLevel,
        });
        const respMaps = await fetch("https://static.developer.riotgames.com/docs/lol/maps.json");
        const Maps = await respMaps.json();
        setMaps(Maps)

      } catch (error) {
        console.error(error);
      }
    };

    fetchSummonerInfo();



  }, [link]);

  useEffect(() => {

    async function getMatchIds() {
      try {
        const response = await fetch(`https://${region}.api.riotgames.com/lol/match/v5/matches/by-puuid/${SummonerInfo.puuid}/ids?&start=0&count=5&api_key=${riotKey}`);
        const data = await response.json();
        return data;
      } catch (error) {
      }
    }

    async function getMatchInfo(matchId) {
      if (region && matchId && riotKey) {

        const response = await fetch(`https://${region}.api.riotgames.com/lol/match/v5/matches/${matchId}?api_key=${riotKey}`);
        const data = await response.json();
        return data;

      }
    }


    const fetchMatches = async () => {
      if (region && riotKey && SummonerInfo.puuid) {
        const matchIds = await getMatchIds();
        const matchPromises = matchIds.map(id => getMatchInfo(id));
        const matchData = await Promise.all(matchPromises);

        matchData.forEach((data, index) => {
          if (data === undefined) {
            console.log(`Error fetching data for match ID: ${matchIds[index]}`);
          }
        });

        setLast20Matches(matchData);
      }
    };

    const fetchItemInfo = async () => {
      if (latestPatch) {
        const response = await fetch(`http://ddragon.leagueoflegends.com/cdn/${latestPatch}/data/en_US/item.json`);
        const itemsinfo = await response.json();
        setItems(itemsinfo)
      }
    };


    fetchItemInfo();
    fetchMatches();



  }, [SummonerInfo.puuid, region, riotKey]);




  const profileIconSRC = () => {
    if (latestPatch && SummonerInfo.profileIconId) {
      return `http://ddragon.leagueoflegends.com/cdn/${latestPatch}/img/profileicon/${SummonerInfo.profileIconId}.png`;
    }
    return null;
  };

  const getChampionImageURL = (championName) => {
    if (latestPatch && championName) {
      return `http://ddragon.leagueoflegends.com/cdn/${latestPatch}/img/champion/${championName}.png`;
    }
  };

  const getItemImageURL = (ItemId) => {
    if (latestPatch && ItemId) {
      if (ItemId === null) { ItemId = "7050" }
      return `http://ddragon.leagueoflegends.com/cdn/${latestPatch}/img/item/${ItemId}.png`;
    }
  };


  const matchItems = last20Matches.map((dataMatch) => {
    const participant = dataMatch.info.participants.find((player) => player.puuid === SummonerInfo.puuid);
    return participant;
  });

  useEffect(() => {
    async function fetchRGBValues() {
      if (last20Matches && last20Matches.length > 0) {
        const updatedRGB = [];
        for (let i = 0; i < last20Matches.length; i++) {
          const itemRGB = [];
          for (let j = 0; j < 7; j++) {
            const imageURL = getItemImageURL(matchItems[i][`item${j}`] !== 0 ? matchItems[i][`item${j}`] : "7050");
            const averageRGB = await getRGB(getAverageRGB, imageURL);
            itemRGB.push(averageRGB);
          }
          updatedRGB.push(itemRGB);
        }
        setRGB({ data: updatedRGB, loading: false });
      }
    }


    fetchRGBValues();
  }, [last20Matches]);

  useEffect(() => {
    getRGB(getAverageRGB, "http://ddragon.leagueoflegends.com/cdn/13.13.1/img/item/7050.png")
      .then(averageRGB => {
      })

  }, [rgb]);

  function getMapImage(maps, dataMatch) {
    const matchedMap = maps.find(map => map.mapId === dataMatch.info.mapId);
    if (matchedMap && (matchedMap.mapName === "Summoner's Rift")) {
      return "/img/5v5.jpg";
    } else if (matchedMap && (matchedMap.mapName === "Twisted Treeline")) {
      return "/img/3v3.jpg";
    } else if (matchedMap && (matchedMap.mapName === "Nexus Blitz")) {
      return "/img/nexus-blitz.jpg";
    } else if ((matchedMap && (matchedMap.mapName === "Howling Abyss")) || (matchedMap && (matchedMap.mapName === "Butcher's Bridge"))) {
      return "/img/aram.jpg";
    }
    return null;
  }


  const seconds = 1638;

  // ✅ get hh:mm:ss string
  const result = new Date(seconds * 1000).toISOString().slice(11, 19);
  console.log(result.slice(3, 9)); // 👉️ "00:10:00" (hh:mm:ss)


  return (
    <div className="container mx-auto py-8 pt-40">
      <div className="flex items-center justify-center">
        <img src={profileIconSRC()} alt="Profil Resmi" className="w-20 h-20 rounded-full" />
      </div>
      <div className="text-center mt-4">
        <h2 className="text-2xl font-bold">{SummonerInfo.name}</h2>
        <p className="text-gray-500">Level: {SummonerInfo.summonerLevel}</p>
        <p className="text-gray-500">Oynanan Maç Sayısı: 1000</p>
      </div>
      {/* Oynanan Şampiyonlar */}
      <div className="mt-8">
        <h3 className="text-xl font-bold">Maç Geçmişi</h3>
        <div className="mt-4">
          <ul className="">

            {last20Matches.map((dataMatch, index) =>
              <div className="backGroundMap" style={{ backgroundImage: `url("${getMapImage(maps, dataMatch)}")` }}>
                <li className={`py-4 px-4 rounded-lg my-2 grid grid-cols-3 max-xl:grid-cols-1 ${(matchItems[index].puuid === SummonerInfo.puuid) && matchItems[index].win ? "winStyle" : "loseStyle"}`} key={index}>

                  <div className="flex items-center grid grid-rows">
                    <div className="grid grid-rows-2">
                      <div className="grid grid-cols-3 mx-1">
                        <div>
                          <img
                            src={getChampionImageURL(dataMatch.info.participants.find((player) => player.puuid === SummonerInfo.puuid).championName)}
                            alt="Şampiyon 1"
                            className="rounded-lg sumChampIcons"
                          />
                        </div>
                        <div>
                          <p className="font-semibold">{dataMatch.info.participants.map(player => (player.puuid === SummonerInfo.puuid ? player.championName : ''))}</p>
                          <p className="text-gray-500">{dataMatch.info.participants.map(player => (player.puuid === SummonerInfo.puuid ? (player.win ? "Victory" : "Defeat") : ''))}</p>
                          <p className="text-gray-500">KDA: {dataMatch.info.participants.map(player => (player.puuid === SummonerInfo.puuid ? `${player.kills} / ${player.deaths} / ${player.assists}` : ''))}</p>
                        </div>
                        <div>
                          {result.slice(3, 9)}
                        </div>
                      </div>

                      <div className="ml-1">
                        <div className="flex items-center bg-red-100 p-1 mr-10 rounded-lg">
                          <p className="mx-2 text-red-800">Red Team:</p>
                          <div className="flex items-center bg-red-200 p-1 rounded-lg">
                            {dataMatch.info.participants.slice(0, 5).map(player =>
                              <img src={getChampionImageURL(player.championName)}
                                className="w-8 h-8 teamIcons rounded-lg" />)}
                          </div>
                        </div>
                        <div className="flex items-center bg-blue-100 p-1 mr-10 rounded-lg">
                          <p className="mx-2 text-blue-800">Blue Team:</p>
                          <div className="flex items-center bg-blue-200 p-1 rounded-lg">
                            {dataMatch.info.participants.slice(5, 11).map(player =>
                              <img src={getChampionImageURL(player.championName)}
                                className="w-8 h-8 teamIcons rounded-lg" />)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid gap-y-3 gap-1 mt-2 grid-cols-4 w-96">
                    {(() => {
                      const elements = [];
                      for (let j = 0; j < 7; j++) {
                        const itemKey = `item${j}`;
                        const itemRGB = rgb.loading ? null : rgb.data[index][j];
                        if (itemRGB && itemRGB.r !== 41 && itemRGB.g !== 44 && itemRGB.b !== 52) {
                          elements.push(
                            <OverlayTrigger
                              key={j}
                              overlay={
                                <Tooltip className="fade-tooltip">
                                  <ItemToolTip delayShow={3000} ItemId={matchItems[index][itemKey]} itemRgb={itemRGB} items={items} />
                                </Tooltip>
                              }
                            >
                              <div
                                className="bg-gray-100 shadow-md py-2 px-0 rounded-lg"
                                style={{
                                  backgroundColor: `rgba(${itemRGB.r + (255 - itemRGB.r) * 0.6}, ${itemRGB.g + (255 - itemRGB.g) * 0.6}, ${itemRGB.b + (255 - itemRGB.b) * 0.6}, 1)`,
                                }}
                              >
                                <img
                                  src={getItemImageURL(matchItems[index][itemKey] !== 0 ? matchItems[index][itemKey] : "7050")}
                                  alt={`Item ${j + 1}`}
                                  className="rounded-lg w-10 h-10 mx-auto"
                                />
                                <p className="text-center mt-2 font-semibold text-sm"
                                  style={{ fontSize: "11px" }}
                                >{items.data[matchItems[index][itemKey]]?.name}</p>
                              </div>
                            </OverlayTrigger>
                          );
                        } else {
                          elements.push(
                            <div key={j} className="bg-gray-100 py-2 rounded-lg">
                              <img
                                src={getItemImageURL(matchItems[index][itemKey] !== 0 ? matchItems[index][itemKey] : "7050")}
                                alt={`Item ${j + 1}`}
                                className="rounded-lg w-10 h-10 mx-auto"
                              />
                              <p className="text-center mt-2 font-semibold">{items.data[matchItems[index][itemKey]]?.name}</p>
                            </div>
                          );
                        }
                      }
                      return elements;
                    })()}


                  </div>
                  <div className="bg-blue-500  mt-2 rounded-lg grid grid-cols-3" style={{
                    backgroundColor: "rgba(192, 247, 160, 0.3)",
                    backdropFilter: "blur(2px)",
                  }}>

                    <div className=" grid-cols-1">
                      {infoText("/icons/Minion.webp","Total CS", matchItems[index].totalMinionsKilled)}
                      {infoText("/icons/Minion.webp","10 min CS", matchItems[index].challenges.laneMinionsFirst10Minutes)}
                      {infoText("/icons/sword-wound.svg","Kill Participation", formatAsPercentage(matchItems[index].challenges.killParticipation))}
                      {infoText("/icons/Attack_damage_icon.webp","Total Damage Dealt", matchItems[index].totalDamageDealtToChampions)}
                      {infoText("/icons/spiked-dragon-head.svg","Dragon Takedowns", matchItems[index].challenges.dragonTakedowns)}
                    </div>

                    <div className="flex grid grid-cols-1 justify-center items-center ">
                      {infoText("/icons/Ward_icon.webp","Vision Score", matchItems[index].visionScore)}
                      {infoText("/icons/Disabled_Ward_icon.webp","Wards Killed", matchItems[index].wardsKilled)}
                      {infoText("/icons/two-coins.svg","Gold Per Minute", Math.floor(matchItems[index].challenges.goldPerMinute))}
                      {infoText("/icons/swap-bag.svg","Gold Earned", matchItems[index].goldEarned)}
                      {infoText("/icons/health-normal.svg","Total Heal", matchItems[index].totalHeal)}
                    </div>
                    <div className="flex grid grid-cols-1 justify-center items-center">
                      {infoText("/icons/swap-bag.svg","All In Pings", matchItems[index].allInPings)}
                      {infoText("/icons/swap-bag.svg","Assist Me Pings", matchItems[index].assistMePings)}
                      {infoText("/icons/swap-bag.svg","Enemy Missing Pings", matchItems[index].enemyMissingPings)}
                      {infoText("/icons/swap-bag.svg","Get Back Pings", matchItems[index].getBackPings)}
                      {infoText("/icons/coming.png","On My Way Pings", matchItems[index].onMyWayPings)}
                    </div>
                  </div>
                </li>
              </div>

            )}

          </ul>
        </div>
      </div>
    </div>
  );
}

export default SearchSummoner;



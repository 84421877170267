  // context.js
  import React, { createContext, useState, useEffect } from 'react';

  const AppContext = createContext();

  const AppProvider = ({ children }) => {
    const [summonerName, setSummonerName] = useState('');
    const [latestPatch, setLatestPatch] = useState('');
    const [server, setServer] = useState('');
    const [region, setRegion] = useState('');
    const [items, setItems] = useState({});
    const [rgb, setRGB] = useState({ data: [], loading: true });


  
    return (
      <AppContext.Provider
        value={{ summonerName, setSummonerName, latestPatch, setLatestPatch, server, setServer, region, setRegion, items, setItems, rgb, setRGB }}
      >
        {children}
      </AppContext.Provider>
    );
  }

  export { AppContext, AppProvider };

import React from 'react';
import { Link } from "react-router-dom";

function SideBar({ isSideBarOpen }) {
    return (
        <div
            className={`sidebar fixed top-0 bottom-0 lg:left-0 p-2 w-[220px] overflow-y-auto text-center bg-gray-800 z-40 transition-transform ${
                isSideBarOpen ? 'translate-x-0 duration-500' : '-translate-x-full duration-500'
            }`}
        >
            <div className="text-gray-100 text-xl">
                <div className="p-2.5 mt-1 flex items-center">
                    <i className="bi bi-app-indicator px-2 py-1 bg-blue-600 rounded-md"></i>
                    <h1 className="font-bold text-gray-200 text-[15px] ml-3">
                        League Of Legends Statistics
                    </h1>
                    <i className="bi bi-x ml-20 cursor-pointer lg:hidden"></i>
                </div>
                <hr className="my-2 text-gray-600" />

                <Link to="/" className="p-1.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
                    <i className="bi bi-house-door-fill"></i>
                    <span className="text-[15px] ml-4 text-gray-200"> Home </span>
                </Link>
                <div className="p-1.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
                    <i className="bi bi-house-door-fill"></i>
                    <span className="text-[15px] ml-4 text-gray-200"> Home </span>
                </div>
            </div>
        </div>
    );
}

export default SideBar;

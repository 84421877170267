// App.js
import React, { useEffect, useState, useContext } from 'react';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';
import TopBar from './TopBar';
import ChampionsPage from './ChampionsPage';
import ChampionPage from './ChampionPage';
import SideBar from './SideBar';
import styles from '../index.css';
import SearchSummoner from './SearchSummoner';
import { AppProvider, AppContext } from './context';

function App() {
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const [selectedChamp, setSelectedChamp] = useState('');
  const [summonerName, setSummonerName] = useState('');
  const { latestPatch, setLatestPatch } = useContext(AppContext);

  useEffect(() => {
    const fetchLatestPatch = async () => {
      const response = await fetch(
        'https://ddragon.leagueoflegends.com/api/versions.json'
      );
      const data = await response.json();
      const latestVersion = data[0];
      setLatestPatch(latestVersion);
    };

    fetchLatestPatch();
  }, []);

  const toggleSideBar = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  const setterSumName = (sumName) => {
    setSummonerName(sumName);
  };

  return (
    <Router>
        <div>
          <div>
            <Link id="home" to="/"></Link>
          </div>

          <Routes>
            <Route
              exact
              path="/"
              element={
                <React.Fragment>
                  <TopBar toggleSideBar={toggleSideBar} />
                  <ChampionsPage setSelectedChamp={setSelectedChamp} />
                  <SideBar isSideBarOpen={isSideBarOpen} />
                </React.Fragment>
              }
            />
            <Route
              path={`/champion`}
              element={
                <React.Fragment>
                  <TopBar toggleSideBar={toggleSideBar} />
                  <ChampionPage selectedChamp={selectedChamp} />
                  <SideBar isSideBarOpen={isSideBarOpen} />
                </React.Fragment>
              }
            />
            <Route
              exact
              path="/summoner"
              element={
                <React.Fragment>
                  <TopBar
                    toggleSideBar={toggleSideBar}
                    setterSumName={setterSumName}
                  />
                  <SearchSummoner summonerName={summonerName} />
                  <SideBar isSideBarOpen={isSideBarOpen} />
                </React.Fragment>
              }
            />
          </Routes>
        </div>
    </Router>
  );
}

export default App;

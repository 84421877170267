import { useState, useEffect, useContext } from "react";
import './ChampionsPage.css';
import getAverageRGB from "./AvarageRGBFunc";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SpellToolTip from "./SpellToolTip";
import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip as tt,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { AppContext } from './context';

ChartJS.register(LineElement, PointElement, Legend, RadialLinearScale, Filler, tt)

function ChampionPage({ selectedChamp}) {
  const { latestPatch } = useContext(AppContext);
  const [champData, setChampData] = useState({});
  const [selectedChampData, setSelectedChampData] = useState({});
  const [rgb, setRGB] = useState({ r: 0, g: 0, b: 0 });
  const [Qrgb, setQRGB] = useState({ r: 0, g: 0, b: 0 });
  const [Wrgb, setWRGB] = useState({ r: 0, g: 0, b: 0 });
  const [Ergb, setERGB] = useState({ r: 0, g: 0, b: 0 });
  const [Rrgb, setRRGB] = useState({ r: 0, g: 0, b: 0 });
  const [spellQUrl, setSpellQUrl] = useState();
  const [spellWUrl, setSpellWUrl] = useState();
  const [spellEUrl, setSpellEUrl] = useState();
  const [spellRUrl, setSpellRUrl] = useState();



  function getRGB(setFunc, getAverageRGB, URL) {
    const img = new Image();
    img.src = URL;
    img.crossOrigin = "Anonymous";

    img.addEventListener('load', () => {
      const averageRGB = getAverageRGB(img);
      setFunc(averageRGB);
    });
  }

  function spellUrl(selectedChamp, champData, spellNumb) {
    const championSpell = champData.data[selectedChamp]?.spells[spellNumb];
    const imageUrl = championSpell ? `http://ddragon.leagueoflegends.com/cdn/13.12.1/img/spell/${championSpell.id}.png` : '';
    return imageUrl
  }

  useEffect(() => {
    const fetchChamp = async () => {
      if (latestPatch && selectedChamp) {
        const response = await fetch(
          `http://ddragon.leagueoflegends.com/cdn/${latestPatch}/data/en_US/champion/${selectedChamp}.json`
        );
        const data = await response.json();
        setChampData(data);
      }
    };

    fetchChamp();
  }, [latestPatch, selectedChamp]);

  useEffect(() => {
    if (champData.data && selectedChamp) {
      getRGB(
        setRGB,
        getAverageRGB,
        `http://ddragon.leagueoflegends.com/cdn/img/champion/splash/${selectedChamp}_0.jpg`
      );
      getRGB(
        setQRGB,
        getAverageRGB,
        `http://ddragon.leagueoflegends.com/cdn/13.12.1/img/spell/${champData.data[selectedChamp]?.spells[0]?.id}.png`
      );
      getRGB(
        setWRGB,
        getAverageRGB,
        `http://ddragon.leagueoflegends.com/cdn/13.12.1/img/spell/${champData.data[selectedChamp]?.spells[1]?.id}.png`
      );
      getRGB(
        setERGB,
        getAverageRGB,
        `http://ddragon.leagueoflegends.com/cdn/13.12.1/img/spell/${champData.data[selectedChamp]?.spells[2]?.id}.png`
      );
      getRGB(
        setRRGB,
        getAverageRGB,
        `http://ddragon.leagueoflegends.com/cdn/13.12.1/img/spell/${champData.data[selectedChamp]?.spells[3]?.id}.png`
      );
      setSpellQUrl(spellUrl(selectedChamp, champData, 0))
      setSpellWUrl(spellUrl(selectedChamp, champData, 1))
      setSpellEUrl(spellUrl(selectedChamp, champData, 2))
      setSpellRUrl(spellUrl(selectedChamp, champData, 3))

      setSelectedChampData(champData.data[selectedChamp]?.info)

    }

  }, [champData, selectedChamp]);

  const data = {
    labels: ['Attack', 'Defense', 'Magic', 'Difficulty'],
    datasets: [
      {
        label: `${selectedChamp}`,
        data: [selectedChampData.attack,
        selectedChampData.defense,
        selectedChampData.magic,
        selectedChampData.difficulty],
        backgroundColor: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2)`,
        borderColor: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`,
        borderWidth: 3,
      },
    ],
  };

  const options = {
    scales: {
      r: {
        beginAtZero: true,
        min: 0,
        max: 10,
        stepSize: 1
      }
    },
  };

  return (<div style={{ background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)` }}>

    <div className="splashArt"
      style={{
        backgroundImage: `url('http://ddragon.leagueoflegends.com/cdn/img/champion/splash/${selectedChamp}_0.jpg')`,
        boxShadow: `inset 0px -100px 50px -48px rgb(${rgb.r + (255 - rgb.r) * 0.3}, ${rgb.g + (255 - rgb.r) * 0.3}, ${rgb.b + (255 - rgb.r) * 0.3}, 1)`,
      }}>
      <h1 className="text1">{champData.data && champData.data[selectedChamp]?.name}</h1>
      <h1 className="text2">{champData.data && champData.data[selectedChamp]?.title}</h1>
    </div>

    <div className="grid container-2xl py-5 sm:px-20 grid-cols-12 gap-5">
      <div className="shadow-lg rounded-lg p-5 text-justify col-start-1 col-end-7 max-sm:mx-3 max-lg:col-end-13"
        style={{ background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)` }}>

        <h1 className="champHeaders text-white text-center"
          style={{
            background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`,
            color: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`,
          }}>Lore</h1>

        <p className="mt-2">{champData.data && champData.data[selectedChamp]?.lore}</p>
      </div>

      <div className="shadow-lg rounded-lg p-5 text-justify col-start-7 col-end-13 max-lg:col-start-1 h-96 max-sm:mx-3" style={{ background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)` }}>
        
        <h1 className="champHeaders text-white text-center"
          style={{
            background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`,
            color: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`,
          }}>Radar Graph</h1>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"90%" }}>
          <Radar data={data} options={options} className="center">
            {/* Radar chart content */}
          </Radar>
        </div>


      </div>

      <div className="shadow-lg rounded-lg p-5 text-justify col-start-1 col-end-7 max-sm:col-end-13 max-sm:mx-3"
        style={{ background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)` }}>

        <h1 className="champHeaders text-white text-center"
          style={{
            background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`,
            color: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`,
          }}>Abilities</h1>

        <div className="abilities-wrap mt-2 p-5"
          style={{
            background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`,
            borderRadius: "10px"
          }}>


          <OverlayTrigger overlay={
            <Tooltip>
              <SpellToolTip spell={champData.data && champData.data[selectedChamp]?.spells[0]} rgb={Qrgb} />
            </Tooltip>}>
            <div style={{ boxShadow: `0px 0px 9px 9px rgba(${Qrgb.r}, ${Qrgb.g}, ${Qrgb.b},0.9)`, borderRadius: "20%" }}>
              <div className="abilities hover:scale-125 duration-500"
                style={{
                  backgroundImage: `url('${spellQUrl}')`,
                }}><h1 className="text-white">Q</h1></div>
            </div>
          </OverlayTrigger>

          <OverlayTrigger overlay={
            <Tooltip>
              <SpellToolTip spell={champData.data && champData.data[selectedChamp]?.spells[1]} rgb={Wrgb} />
            </Tooltip>}>
            <div style={{ boxShadow: `0px 0px 9px 9px rgba(${Wrgb.r}, ${Wrgb.g}, ${Wrgb.b},0.9)`, borderRadius: "20%" }}>
              <div className="abilities hover:scale-125 duration-500"
                style={{
                  backgroundImage: `url('${spellWUrl}')`,
                }}><h1 className=" text-white">W</h1></div>
            </div>
          </OverlayTrigger>

          <OverlayTrigger overlay={
            <Tooltip>
              <SpellToolTip spell={champData.data && champData.data[selectedChamp]?.spells[2]} rgb={Ergb} />
            </Tooltip>}>
            <div style={{ boxShadow: `0px 0px 9px 9px rgba(${Ergb.r}, ${Ergb.g}, ${Ergb.b},0.9)`, borderRadius: "20%" }}>
              <div className="abilities hover:scale-125 duration-500"
                style={{
                  backgroundImage: `url('${spellEUrl}')`,
                }}><h1 className=" text-white">E</h1></div>
            </div>
          </OverlayTrigger>

          <OverlayTrigger overlay={
            <Tooltip>
              <SpellToolTip spell={champData.data && champData.data[selectedChamp]?.spells[3]} rgb={Rrgb} />
            </Tooltip>}>
            <div style={{ boxShadow: `0px 0px 9px 9px rgba(${Rrgb.r}, ${Rrgb.g}, ${Rrgb.b},0.9)`, borderRadius: "20%" }}>
              <div className="abilities hover:scale-125 duration-500"
                style={{
                  backgroundImage: `url('${spellRUrl}')`,
                }}><h1 className=" text-white">R</h1></div>
            </div>
          </OverlayTrigger>

        </div>
      </div>
      <div className="shadow-lg rounded-lg p-5 text-justify col-start-7 col-end-13 max-sm:col-start-1 max-sm:mx-3"
        style={{ background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)` }}>

        <h1 className="champHeaders text-white text-center"
          style={{
            background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`,
            color: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`,
          }}>Blurb</h1>

        <p className="mt-2">{champData.data && champData.data[selectedChamp]?.blurb}</p>
      </div>
      <div className="shadow-lg rounded-lg p-5 text-justify col-start-1 col-end-7 max-lg:col-end-13 max-sm:mx-3"
        style={{ background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)` }}>

        <h1 className="champHeaders text-white text-center"
          style={{
            background: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`,
            color: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)`,
          }}>Tips</h1>

        <p className="mt-2">{champData.data && champData.data[selectedChamp]?.allytips}</p>
      </div>
    </div>
  </div>
  );
}

export default ChampionPage;



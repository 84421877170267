import React from 'react';
import './ChampionsPage.css';


function getContrastingTextColor(rgb) {
  // Calculate the relative luminance of the background color
  const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;

  // Choose a contrasting text color based on the luminance
  return luminance > 0.5 ? '#000000' : '#ffffff';
}

function SpellToolTip({ spell, rgb }) {
  const textColor = getContrastingTextColor(rgb);

  const data = {
    labels: [
      'Eating',
      'Drinking',
      'Sleeping',
      'Designing',
      'Coding',
      'Cycling',
      'Running'
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [65, 59, 90, 81, 56, 55, 40],
      fill: true,
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgb(255, 99, 132)',
      pointBackgroundColor: 'rgb(255, 99, 132)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(255, 99, 132)'
    }, {
      label: 'My Second Dataset',
      data: [28, 48, 40, 19, 96, 27, 100],
      fill: true,
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgb(54, 162, 235)',
      pointBackgroundColor: 'rgb(54, 162, 235)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(54, 162, 235)'
    }]
  };

  const config = {
    type: 'radar',
    data: data,
    options: {
      elements: {
        line: {
          borderWidth: 3
        }
      }
    },
  };

  return (
    <div
      className='w-96 mb-5 rounded-xl z-50 max-sm:mx-3 max-sm:w-64'
      style={{
        backgroundColor: `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`,
        filter: "contrast(100%)"
      }}
    >
      <h1
        className="champHeaders text-center m-3"
        style={{
          backgroundColor: `rgb(${rgb.r + (255 - rgb.r) * 0.3}, ${rgb.g + (255 - rgb.r) * 0.3}, ${rgb.b + (255 - rgb.r) * 0.3})`,
          borderRadius: "10px",
          color: textColor,
          display: "inline-block",
          paddingInline: "10px "
        }}
      >
        {spell.name}
      </h1>
      <div className='py-2 px-3 mx-3 mb-3 text-justify'
        style={{
          backgroundColor: `rgb(${rgb.r + (255 - rgb.r) * 0.3}, ${rgb.g + (255 - rgb.r) * 0.3}, ${rgb.b + (255 - rgb.r) * 0.3})`,
          borderRadius: "10px",
        }}
      >
        <p className='text-sm' style={{ color: textColor }}>{spell.description}</p>
      </div>

      <div className='py-2 px-3 mx-3 mb-3 text-justify'
        style={{
          backgroundColor: `rgb(${rgb.r + (255 - rgb.r) * 0.3}, ${rgb.g + (255 - rgb.r) * 0.3}, ${rgb.b + (255 - rgb.r) * 0.3})`,
          borderRadius: "10px",
          color: textColor
        }}
      >
        Cooldown:
        {spell.cooldown.map((cooldown, index) => {
          if (index !== spell.maxrank - 1) {
            return ` ${cooldown} /`
          } else {
            return ` ${cooldown} sec.`
          }
        })}
      </div>

      <div className='h-1'></div>

    </div>
  );
}

export default SpellToolTip;

import { useState, useEffect,useContext } from "react";
import './ChampionsPage.css';
import { Link } from "react-router-dom";
import { AppContext } from './context';


function ChampionsPage({ setSelectedChamp }) {
  const [champions, setChampions] = useState([]);
  const { latestPatch, setLatestPatch } = useContext(AppContext);
  
  useEffect(() => {
    const fetchChampions = async () => {
      if (latestPatch) {
      const response = await fetch(
        `http://ddragon.leagueoflegends.com/cdn/${latestPatch}/data/en_US/champion.json`
      );
      const data = await response.json();
      const championData = Object.values(data.data);
      setChampions(championData);
    };
    }

    fetchChampions();
  }, [latestPatch]);

  return (
    <>
      <div className="container-2xl py-20 lg:px-40 mx-auto sm:px-20">
        <h1 className="text-3xl font-bold underline"></h1>
        <div className="grid 2xl:grid-cols-11 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-6 sm:grid-cols-4 max-sm:grid-cols-4 :grid-cols-4 gap-1">
          {champions.map((champion) => (
            <Link to={`/champion`} onClick={() => setSelectedChamp(champion.id)} key={champion.id}>
              <div className="flex flex-col items-center bg-white-500 rounded-lg shadow-md drop-shadow-xl hover:shadow-inner hover:cursor-pointer py-1 my-2">
                <img
                  src={`http://ddragon.leagueoflegends.com/cdn/${latestPatch}/img/champion/${champion.id}.png`}
                  className="champIcons z-1"
                  alt={champion.name}
                />
                <h6 className="text-xs">{champion.name}</h6>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default ChampionsPage;
